@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.gap-10{
  gap: 10px;
}

.as-scores-main-wrapper{
  z-index: 999;
  background: url('/bg-main.png') top no-repeat;
  background-size: 100%;
  height: 120px;
  display: flex;
  position: relative;
  overflow: hidden;  /* Ẩn các phần tử tràn ra ngoài */
  width: 100%; 
  flex-direction: column;
  justify-content: flex-start;
} 

.as-scores-items{
  height: 48px;
  width: 98% !important;
  margin: 0 auto !important;
  margin-top: 5px !important;
  z-index: 999;
}

.as-scores-items:last-child{
  margin-top: 1px !important;
}

.as-scores-wrapper{
  top: -10px;
}
.as-scores-main-wrapper .as-scores-items:last-child{
  padding: 0px;
}

.as-scores-items 

.as-scores-bg-wrapper{
  display: inline-flex;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  font-family: "Oswald", sans-serif;
  gap: 0px;
  margin: 0 auto;
  display: flex;
  transition: transform 0.3s ease;
  height: 48px;
  position: relative;
  z-index: 999;
}

.as-scores-wrapper{
  display: inline-flex;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  gap: 0px;
  padding: 0px 30px;
  background: url('/bg-socres.png');
  background-size: 100% 100%;
  height: 44px;
}
.as-timeline-wrapper{
  display: flex;
  gap: 5px;
  align-items: center;
}

.as-timeline-img{
  width: 24px;
  height: auto;
}

.as-timeline-minutes span{
  color: #FFF;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.146px; 
}

.as-timeline-minutes span.as-end{
  font-size: 10px;
}

.as-team-wrapper{
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.as-wrapper-player{
  flex: 1;
  display: inline-flex;
  align-items: center;
  gap: 2px;
  white-space: nowrap;
  overflow: hidden;

}
.as-wrapper-player.as-home .as-item-info{
    text-align: right;
}

.as-score-wrapper{
  padding: 0px 5px;
  display: flex;
  gap: 5px;
}

.as-score-wrapper span{
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.as-score-wrapper span.as-goal{
  color: #00FF5D;
}
.as-wrapper-player.as-away{
  flex-direction: row-reverse;
}

img.as-img-player{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: contain;
  /* background: #FFFFFF;
  border: 1px solid #fff; */
}

.as-item-img{
  display: inline-flex;
}
.as-item-info{
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  padding-top: 3px;
}

.as-item-info span{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-family: "Roboto", sans-serif;

  color: #FFF;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; 
}

.as-item-info span.as-child{
  font-size: 10px;
  font-weight: 400;
}

.as-wrapper-item{
  flex: 1;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  gap: 5px;
}
@media (max-width: 320px){
  .as-scores-wrapper{
    padding: 0px 15px;
  }

  .as-timeline-img{
    width: 30px;
  }
}
.as-handle-left{
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
  z-index: 9999;
  width: 10px;
  height: auto;
}

.as-handle-right{
  position: absolute;
  cursor: pointer;
  z-index: 9999;
  top: 10px;
  right: 20px;
  height: auto;
}

.as-icon-handle{
  width: 7px;
  height: auto;
}