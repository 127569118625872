*,
body,
ul,
p,
h1,
h2,
h3,
input {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.popover-emoji .EmojiPickerReact {
  --epr-emoji-size: 20px;
  --epr-emoji-padding: 1px;
  --epr-category-label-height: auto;
  font-size: 1rem;
}
.popover-emoji .ant-popover-inner-content {
  padding: 2px;
}
.popover-emoji .ant-popover-inner-content aside.EmojiPickerReact.epr-main {
  border: none;
}
.popover-color-picker .ant-popover-inner-content {
  padding: 0;
}
html {
  font-size: 13px;
}

body {
  overflow-x: hidden;
}
.form-dashboard .inline .label {
  font-size: 14px !important;
}
.text-bold {
  font-weight: 600;
}
.text-nowrap {
  white-space: nowrap;
}
/*MARGIN*/
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}
.mx-2 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}
.mx-3 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}
.mx-4 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}
.mx-5 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.mx-6 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}
.mx-7 {
  margin-right: 28px !important;
  margin-left: 28px !important;
}
.mx-8 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}
.mx-9 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}
.mx-10 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}
.mx-11 {
  margin-right: 44px !important;
  margin-left: 44px !important;
}
.mx-12 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}
.mx-13 {
  margin-right: 52px !important;
  margin-left: 52px !important;
}
.mx-14 {
  margin-right: 56px !important;
  margin-left: 56px !important;
}
.mx-15 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}
.mx-16 {
  margin-right: 64px !important;
  margin-left: 64px !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}
.my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.my-9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}
.my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.my-11 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}
.my-12 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.my-13 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}
.my-14 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}
.my-15 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.my-16 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 4px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mt-3 {
  margin-top: 12px !important;
}
.mt-4 {
  margin-top: 16px !important;
}
.mt-5 {
  margin-top: 20px !important;
}
.mt-6 {
  margin-top: 24px !important;
}
.mt-7 {
  margin-top: 28px !important;
}
.mt-8 {
  margin-top: 32px !important;
}
.mt-9 {
  margin-top: 36px !important;
}
.mt-10 {
  margin-top: 40px !important;
}
.mt-11 {
  margin-top: 44px !important;
}
.mt-12 {
  margin-top: 48px !important;
}
.mt-13 {
  margin-top: 52px !important;
}
.mt-14 {
  margin-top: 56px !important;
}
.mt-15 {
  margin-top: 60px !important;
}
.mt-16 {
  margin-top: 64px !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mr-3 {
  margin-right: 12px !important;
}
.mr-4 {
  margin-right: 16px !important;
}
.mr-5 {
  margin-right: 20px !important;
}
.mr-6 {
  margin-right: 24px !important;
}
.mr-7 {
  margin-right: 28px !important;
}
.mr-8 {
  margin-right: 32px !important;
}
.mr-9 {
  margin-right: 36px !important;
}
.mr-10 {
  margin-right: 40px !important;
}
.mr-11 {
  margin-right: 44px !important;
}
.mr-12 {
  margin-right: 48px !important;
}
.mr-13 {
  margin-right: 52px !important;
}
.mr-14 {
  margin-right: 56px !important;
}
.mr-15 {
  margin-right: 60px !important;
}
.mr-16 {
  margin-right: 64px !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.mb-3 {
  margin-bottom: 12px !important;
}
.mb-4 {
  margin-bottom: 16px !important;
}
.mb-5 {
  margin-bottom: 20px !important;
}
.mb-6 {
  margin-bottom: 24px !important;
}
.mb-7 {
  margin-bottom: 28px !important;
}
.mb-8 {
  margin-bottom: 32px !important;
}
.mb-9 {
  margin-bottom: 36px !important;
}
.mb-10 {
  margin-bottom: 40px !important;
}
.mb-11 {
  margin-bottom: 44px !important;
}
.mb-12 {
  margin-bottom: 48px !important;
}
.mb-13 {
  margin-bottom: 52px !important;
}
.mb-14 {
  margin-bottom: 56px !important;
}
.mb-15 {
  margin-bottom: 60px !important;
}
.mb-16 {
  margin-bottom: 64px !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 4px !important;
}
.ml-2 {
  margin-left: 8px !important;
}
.ml-3 {
  margin-left: 12px !important;
}
.ml-4 {
  margin-left: 16px !important;
}
.ml-5 {
  margin-left: 20px !important;
}
.ml-6 {
  margin-left: 24px !important;
}
.ml-7 {
  margin-left: 28px !important;
}
.ml-8 {
  margin-left: 32px !important;
}
.ml-9 {
  margin-left: 36px !important;
}
.ml-10 {
  margin-left: 40px !important;
}
.ml-11 {
  margin-left: 44px !important;
}
.ml-12 {
  margin-left: 48px !important;
}
.ml-13 {
  margin-left: 52px !important;
}
.ml-14 {
  margin-left: 56px !important;
}
.ml-15 {
  margin-left: 60px !important;
}
.ml-16 {
  margin-left: 64px !important;
}
.ml-auto {
  margin-left: auto !important;
}
.ma-0 {
  margin: 0 !important;
}
.ma-1 {
  margin: 4px !important;
}
.ma-2 {
  margin: 8px !important;
}
.ma-3 {
  margin: 12px !important;
}
.ma-4 {
  margin: 16px !important;
}
.ma-5 {
  margin: 20px !important;
}
.ma-6 {
  margin: 24px !important;
}
.ma-7 {
  margin: 28px !important;
}
.ma-8 {
  margin: 32px !important;
}
.ma-9 {
  margin: 36px !important;
}
.ma-10 {
  margin: 40px !important;
}
.ma-11 {
  margin: 44px !important;
}
.ma-12 {
  margin: 48px !important;
}
.ma-13 {
  margin: 52px !important;
}
.ma-14 {
  margin: 56px !important;
}
.ma-15 {
  margin: 60px !important;
}
.ma-16 {
  margin: 64px !important;
}
.ma-auto {
  margin: auto !important;
}
/*PADDING*/
.pa-0 {
  padding: 0 !important;
}
.pa-1 {
  padding: 4px !important;
}
.pa-2 {
  padding: 8px !important;
}
.pa-3 {
  padding: 12px !important;
}
.pa-4 {
  padding: 16px !important;
}
.pa-5 {
  padding: 20px !important;
}
.pa-6 {
  padding: 24px !important;
}
.pa-7 {
  padding: 28px !important;
}
.pa-8 {
  padding: 32px !important;
}
.pa-9 {
  padding: 36px !important;
}
.pa-10 {
  padding: 40px !important;
}
.pa-11 {
  padding: 44px !important;
}
.pa-12 {
  padding: 48px !important;
}
.pa-13 {
  padding: 52px !important;
}
.pa-14 {
  padding: 56px !important;
}
.pa-15 {
  padding: 60px !important;
}
.pa-16 {
  padding: 64px !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}
.px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}
.px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.px-5 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.px-6 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}
.px-7 {
  padding-right: 28px !important;
  padding-left: 28px !important;
}
.px-8 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}
.px-9 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}
.px-10 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.px-11 {
  padding-right: 44px !important;
  padding-left: 44px !important;
}
.px-12 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}
.px-13 {
  padding-right: 52px !important;
  padding-left: 52px !important;
}
.px-14 {
  padding-right: 56px !important;
  padding-left: 56px !important;
}
.px-15 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}
.px-16 {
  padding-right: 64px !important;
  padding-left: 64px !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}
.py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}
.py-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.py-13 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}
.py-14 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}
.py-15 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.py-16 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pt-3 {
  padding-top: 12px !important;
}
.pt-4 {
  padding-top: 16px !important;
}
.pt-5 {
  padding-top: 20px !important;
}
.pt-6 {
  padding-top: 24px !important;
}
.pt-7 {
  padding-top: 28px !important;
}
.pt-8 {
  padding-top: 32px !important;
}
.pt-9 {
  padding-top: 36px !important;
}
.pt-10 {
  padding-top: 40px !important;
}
.pt-11 {
  padding-top: 44px !important;
}
.pt-12 {
  padding-top: 48px !important;
}
.pt-13 {
  padding-top: 52px !important;
}
.pt-14 {
  padding-top: 56px !important;
}
.pt-15 {
  padding-top: 60px !important;
}
.pt-16 {
  padding-top: 64px !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 4px !important;
}
.pr-2 {
  padding-right: 8px !important;
}
.pr-3 {
  padding-right: 12px !important;
}
.pr-4 {
  padding-right: 16px !important;
}
.pr-5 {
  padding-right: 20px !important;
}
.pr-6 {
  padding-right: 24px !important;
}
.pr-7 {
  padding-right: 28px !important;
}
.pr-8 {
  padding-right: 32px !important;
}
.pr-9 {
  padding-right: 36px !important;
}
.pr-10 {
  padding-right: 40px !important;
}
.pr-11 {
  padding-right: 44px !important;
}
.pr-12 {
  padding-right: 48px !important;
}
.pr-13 {
  padding-right: 52px !important;
}
.pr-14 {
  padding-right: 56px !important;
}
.pr-15 {
  padding-right: 60px !important;
}
.pr-16 {
  padding-right: 64px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 4px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pb-3 {
  padding-bottom: 12px !important;
}
.pb-4 {
  padding-bottom: 16px !important;
}
.pb-5 {
  padding-bottom: 20px !important;
}
.pb-6 {
  padding-bottom: 24px !important;
}
.pb-7 {
  padding-bottom: 28px !important;
}
.pb-8 {
  padding-bottom: 32px !important;
}
.pb-9 {
  padding-bottom: 36px !important;
}
.pb-10 {
  padding-bottom: 40px !important;
}
.pb-11 {
  padding-bottom: 44px !important;
}
.pb-12 {
  padding-bottom: 48px !important;
}
.pb-13 {
  padding-bottom: 52px !important;
}
.pb-14 {
  padding-bottom: 56px !important;
}
.pb-15 {
  padding-bottom: 60px !important;
}
.pb-16 {
  padding-bottom: 64px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 4px !important;
}
.pl-2 {
  padding-left: 8px !important;
}
.pl-3 {
  padding-left: 12px !important;
}
.pl-4 {
  padding-left: 16px !important;
}
.pl-5 {
  padding-left: 20px !important;
}
.pl-6 {
  padding-left: 24px !important;
}
.pl-7 {
  padding-left: 28px !important;
}
.pl-8 {
  padding-left: 32px !important;
}
.pl-9 {
  padding-left: 36px !important;
}
.pl-10 {
  padding-left: 40px !important;
}
.pl-11 {
  padding-left: 44px !important;
}
.pl-12 {
  padding-left: 48px !important;
}
.pl-13 {
  padding-left: 52px !important;
}
.pl-14 {
  padding-left: 56px !important;
}
.pl-15 {
  padding-left: 60px !important;
}
.pl-16 {
  padding-left: 64px !important;
}
/*TEXT*/
.text-center {
  text-align: center !important;
}
.text-no-wrap {
  white-space: nowrap !important;
}

/*FLEX*/
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
/*JUSTIFY*/
.justify-start {
  justify-content: flex-start !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-space-between {
  justify-content: space-between !important;
}
.justify-space-around {
  justify-content: space-around !important;
}
/*ALIGN*/
.align-start {
  align-items: flex-start !important;
}
.align-end {
  align-items: flex-end !important;
}
.align-center {
  align-items: center !important;
}
.align-baseline {
  align-items: baseline !important;
}
.align-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-space-between {
  align-content: space-between !important;
}
.align-content-space-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
/*POSITION*/
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
/*DISPLAY*/
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-grid {
  display: grid !important;
}

/*OVERFLOW*/
.overflow-visible {
  overflow: visible;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-auto {
  overflow: auto;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-y-auto {
  overflow-y: auto;
}

/*Z-INDEX*/
.z-1 {
  z-index: 1 !important;
}
.z-10 {
  z-index: 10 !important;
}
.z-100 {
  z-index: 100 !important;
}
.z-1000 {
  z-index: 1000 !important;
}
.z-10000 {
  z-index: 10000 !important;
}

/*HEIGHT&WIDTH*/
.w-90 {
  width: 90% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-100 {
  height: 100% !important;
}
.h-100vh {
  height: 100vh !important;
}
.h-auto {
  height: auto !important;
}
/*RESPONSIVE*/

@media (max-width: 576px) {
  /*FLEX*/
  .sm-flex-fill {
    flex: 1 1 auto !important;
  }
  .sm-flex-row {
    flex-direction: row !important;
  }
  .sm-flex-column {
    flex-direction: column !important;
  }
  .sm-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .sm-flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .sm-flex-grow-0 {
    flex-grow: 0 !important;
  }
  .sm-flex-grow-1 {
    flex-grow: 1 !important;
  }
  .sm-flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .sm-flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .sm-flex-wrap {
    flex-wrap: wrap !important;
  }
  .sm-flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .sm-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  /*JUSTIFY*/
  .sm-justify-start {
    justify-content: flex-start !important;
  }
  .sm-justify-end {
    justify-content: flex-end !important;
  }
  .sm-justify-center {
    justify-content: center !important;
  }
  .sm-justify-space-between {
    justify-content: space-between !important;
  }
  .sm-justify-space-around {
    justify-content: space-around !important;
  }
  /*DISPLAY*/
  .sm-d-none {
    display: none !important;
  }
  .sm-d-inline {
    display: inline !important;
  }
  .sm-d-inline-block {
    display: inline-block !important;
  }
  .sm-d-block {
    display: block !important;
  }
  .sm-d-table {
    display: table !important;
  }
  .sm-d-table-row {
    display: table-row !important;
  }
  .sm-d-table-cell {
    display: table-cell !important;
  }
  .sm-d-flex {
    display: flex !important;
  }
  .sm-d-inline-flex {
    display: inline-flex !important;
  }
  .sm-d-grid {
    display: grid !important;
  }
  /*ALIGN*/
  .sm-align-start {
    align-items: flex-start !important;
  }
  .sm-align-end {
    align-items: flex-end !important;
  }
  .sm-align-center {
    align-items: center !important;
  }
  .sm-align-baseline {
    align-items: baseline !important;
  }
  .sm-align-stretch {
    align-items: stretch !important;
  }
  .sm-align-content-start {
    align-content: flex-start !important;
  }
  .sm-align-content-end {
    align-content: flex-end !important;
  }
  .sm-align-content-center {
    align-content: center !important;
  }
  .sm-align-content-space-between {
    align-content: space-between !important;
  }
  .sm-align-content-space-around {
    align-content: space-around !important;
  }
  .sm-align-content-stretch {
    align-content: stretch !important;
  }
  .sm-align-self-auto {
    align-self: auto !important;
  }
  .sm-align-self-start {
    align-self: flex-start !important;
  }
  .sm-align-self-end {
    align-self: flex-end !important;
  }
  .sm-align-self-center {
    align-self: center !important;
  }
  .sm-align-self-baseline {
    align-self: baseline !important;
  }
  .sm-align-self-stretch {
    align-self: stretch !important;
  }
  /*POSITION*/
  .sm-position-static {
    position: static !important;
  }
  .sm-position-relative {
    position: relative !important;
  }
  .sm-position-absolute {
    position: absolute !important;
  }
  .sm-position-fixed {
    position: fixed !important;
  }
  .sm-position-sticky {
    position: sticky !important;
  }
}

@media (max-width: 768px) {
  /*FLEX*/
  .md-flex-fill {
    flex: 1 1 auto !important;
  }
  .md-flex-row {
    flex-direction: row !important;
  }
  .md-flex-column {
    flex-direction: column !important;
  }
  .md-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .md-flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .md-flex-grow-0 {
    flex-grow: 0 !important;
  }
  .md-flex-grow-1 {
    flex-grow: 1 !important;
  }
  .md-flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .md-flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .md-flex-wrap {
    flex-wrap: wrap !important;
  }
  .md-flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .md-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  /*JUSTIFY*/
  .md-justify-start {
    justify-content: flex-start !important;
  }
  .md-justify-end {
    justify-content: flex-end !important;
  }
  .md-justify-center {
    justify-content: center !important;
  }
  .md-justify-space-between {
    justify-content: space-between !important;
  }
  .md-justify-space-around {
    justify-content: space-around !important;
  }
  /*DISPLAY*/
  .md-d-none {
    display: none !important;
  }
  .md-d-inline {
    display: inline !important;
  }
  .md-d-inline-block {
    display: inline-block !important;
  }
  .md-d-block {
    display: block !important;
  }
  .md-d-table {
    display: table !important;
  }
  .md-d-table-row {
    display: table-row !important;
  }
  .md-d-table-cell {
    display: table-cell !important;
  }
  .md-d-flex {
    display: flex !important;
  }
  .md-d-inline-flex {
    display: inline-flex !important;
  }
  .md-d-grid {
    display: grid !important;
  }
  /*ALIGN*/
  .md-align-start {
    align-items: flex-start !important;
  }
  .md-align-end {
    align-items: flex-end !important;
  }
  .md-align-center {
    align-items: center !important;
  }
  .md-align-baseline {
    align-items: baseline !important;
  }
  .md-align-stretch {
    align-items: stretch !important;
  }
  .md-align-content-start {
    align-content: flex-start !important;
  }
  .md-align-content-end {
    align-content: flex-end !important;
  }
  .md-align-content-center {
    align-content: center !important;
  }
  .md-align-content-space-between {
    align-content: space-between !important;
  }
  .md-align-content-space-around {
    align-content: space-around !important;
  }
  .md-align-content-stretch {
    align-content: stretch !important;
  }
  .md-align-self-auto {
    align-self: auto !important;
  }
  .md-align-self-start {
    align-self: flex-start !important;
  }
  .md-align-self-end {
    align-self: flex-end !important;
  }
  .md-align-self-center {
    align-self: center !important;
  }
  .md-align-self-baseline {
    align-self: baseline !important;
  }
  .md-align-self-stretch {
    align-self: stretch !important;
  }
  /*POSITION*/
  .md-position-static {
    position: static !important;
  }
  .md-position-relative {
    position: relative !important;
  }
  .md-position-absolute {
    position: absolute !important;
  }
  .md-position-fixed {
    position: fixed !important;
  }
  .md-position-sticky {
    position: sticky !important;
  }
}

@media (max-width: 992px) {
  /*FLEX*/
  .lg-flex-fill {
    flex: 1 1 auto !important;
  }
  .lg-flex-row {
    flex-direction: row !important;
  }
  .lg-flex-column {
    flex-direction: column !important;
  }
  .lg-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .lg-flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .lg-flex-grow-0 {
    flex-grow: 0 !important;
  }
  .lg-flex-grow-1 {
    flex-grow: 1 !important;
  }
  .lg-flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .lg-flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .lg-flex-wrap {
    flex-wrap: wrap !important;
  }
  .lg-flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .lg-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  /*JUSTIFY*/
  .lg-justify-start {
    justify-content: flex-start !important;
  }
  .lg-justify-end {
    justify-content: flex-end !important;
  }
  .lg-justify-center {
    justify-content: center !important;
  }
  .lg-justify-space-between {
    justify-content: space-between !important;
  }
  .lg-justify-space-around {
    justify-content: space-around !important;
  }
  /*DISPLAY*/
  .lg-d-none {
    display: none !important;
  }
  .lg-d-inline {
    display: inline !important;
  }
  .lg-d-inline-block {
    display: inline-block !important;
  }
  .lg-d-block {
    display: block !important;
  }
  .lg-d-table {
    display: table !important;
  }
  .lg-d-table-row {
    display: table-row !important;
  }
  .lg-d-table-cell {
    display: table-cell !important;
  }
  .lg-d-flex {
    display: flex !important;
  }
  .lg-d-inline-flex {
    display: inline-flex !important;
  }
  .lg-d-grid {
    display: grid !important;
  }
  /*ALIGN*/
  .lg-align-start {
    align-items: flex-start !important;
  }
  .lg-align-end {
    align-items: flex-end !important;
  }
  .lg-align-center {
    align-items: center !important;
  }
  .lg-align-baseline {
    align-items: baseline !important;
  }
  .lg-align-stretch {
    align-items: stretch !important;
  }
  .lg-align-content-start {
    align-content: flex-start !important;
  }
  .lg-align-content-end {
    align-content: flex-end !important;
  }
  .lg-align-content-center {
    align-content: center !important;
  }
  .lg-align-content-space-between {
    align-content: space-between !important;
  }
  .lg-align-content-space-around {
    align-content: space-around !important;
  }
  .lg-align-content-stretch {
    align-content: stretch !important;
  }
  .lg-align-self-auto {
    align-self: auto !important;
  }
  .lg-align-self-start {
    align-self: flex-start !important;
  }
  .lg-align-self-end {
    align-self: flex-end !important;
  }
  .lg-align-self-center {
    align-self: center !important;
  }
  .lg-align-self-baseline {
    align-self: baseline !important;
  }
  .lg-align-self-stretch {
    align-self: stretch !important;
  }
  /*POSITION*/
  .lg-position-static {
    position: static !important;
  }
  .lg-position-relative {
    position: relative !important;
  }
  .lg-position-absolute {
    position: absolute !important;
  }
  .lg-position-fixed {
    position: fixed !important;
  }
  .lg-position-sticky {
    position: sticky !important;
  }
}

@media (max-width: 1200px) {
  /*FLEX*/
  .xlg-flex-fill {
    flex: 1 1 auto !important;
  }
  .xlg-flex-row {
    flex-direction: row !important;
  }
  .xlg-flex-column {
    flex-direction: column !important;
  }
  .xlg-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xlg-flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .xlg-flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xlg-flex-grow-1 {
    flex-grow: 1 !important;
  }
  .xlg-flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xlg-flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .xlg-flex-wrap {
    flex-wrap: wrap !important;
  }
  .xlg-flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .xlg-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  /*JUSTIFY*/
  .xlg-justify-start {
    justify-content: flex-start !important;
  }
  .xlg-justify-end {
    justify-content: flex-end !important;
  }
  .xlg-justify-center {
    justify-content: center !important;
  }
  .xlg-justify-space-between {
    justify-content: space-between !important;
  }
  .xlg-justify-space-around {
    justify-content: space-around !important;
  }
  /*DISPLAY*/
  .xlg-d-none {
    display: none !important;
  }
  .xlg-d-inline {
    display: inline !important;
  }
  .xlg-d-inline-block {
    display: inline-block !important;
  }
  .xlg-d-block {
    display: block !important;
  }
  .xlg-d-table {
    display: table !important;
  }
  .xlg-d-table-row {
    display: table-row !important;
  }
  .xlg-d-table-cell {
    display: table-cell !important;
  }
  .xlg-d-flex {
    display: flex !important;
  }
  .xlg-d-inline-flex {
    display: inline-flex !important;
  }
  .xlg-d-grid {
    display: grid !important;
  }
  /*ALIGN*/
  .xlg-align-start {
    align-items: flex-start !important;
  }
  .xlg-align-end {
    align-items: flex-end !important;
  }
  .xlg-align-center {
    align-items: center !important;
  }
  .xlg-align-baseline {
    align-items: baseline !important;
  }
  .xlg-align-stretch {
    align-items: stretch !important;
  }
  .xlg-align-content-start {
    align-content: flex-start !important;
  }
  .xlg-align-content-end {
    align-content: flex-end !important;
  }
  .xlg-align-content-center {
    align-content: center !important;
  }
  .xlg-align-content-space-between {
    align-content: space-between !important;
  }
  .xlg-align-content-space-around {
    align-content: space-around !important;
  }
  .xlg-align-content-stretch {
    align-content: stretch !important;
  }
  .xlg-align-self-auto {
    align-self: auto !important;
  }
  .xlg-align-self-start {
    align-self: flex-start !important;
  }
  .xlg-align-self-end {
    align-self: flex-end !important;
  }
  .xlg-align-self-center {
    align-self: center !important;
  }
  .xlg-align-self-baseline {
    align-self: baseline !important;
  }
  .xlg-align-self-stretch {
    align-self: stretch !important;
  }
  /*POSITION*/
  .xlg-position-static {
    position: static !important;
  }
  .xlg-position-relative {
    position: relative !important;
  }
  .xlg-position-absolute {
    position: absolute !important;
  }
  .xlg-position-fixed {
    position: fixed !important;
  }
  .xlg-position-sticky {
    position: sticky !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ant-modal-body {
  padding: 10px !important;
}

.ps .ps__rail-y,
.ps .ps__rail-y:hover{
  background-color: transparent !important;
}