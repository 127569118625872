@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.ant-notification.ant-notification-bottomLeft {
  margin-left: 0;
  width: 100%;
  max-width: unset;
}
.ant-notification-notice.ant-notification-send-message-error {
  padding: 7px 5px;
  margin-bottom: 24px;
  margin-left: 0;
  border-radius: 0;
  width: 100%;
  /* animation-name: none !important; */
  opacity: 1;
	animation-name: fadeInOpacity !important;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .2s;
}
.ant-notification-notice.ant-notification-send-message-error .ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
}
.ant-notification-notice.ant-notification-send-message-error .ant-notification-notice-message {
  display: none;
}
.ant-notification-notice.ant-notification-send-message-error .ant-notification-notice-icon {
  position: relative;
  height: 24px;
}
.ant-notification-notice.ant-notification-send-message-error .ant-notification-notice-description {
  margin-left: 10px;
  margin-bottom: -5px;
}
.ant-notification-notice.ant-notification-send-message-error .ant-notification-notice-close {
  top: 10px;
}
